import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentUser : null,
    error : null,
    loading : false
}

const userSlice = createSlice({
    name:'user',
    initialState,
    reducers:{
        signInStart:(state)=>{
            state.loading = true;
            state.error = null;
        },
        signInSuccess : (state,action)=>{
            state.currentUser = action.payload
            state.loading = false;
            state.error = null;
        },
        signInFailure:(state,action)=>{
            state.loading = false;
            state.error = action.payload;
        },
      passcodeFailure: (state, action) => {
         state.error = action.payload;
        },
      clearError: (state) => {
      state.error = null; // Action to clear error
    }
    }
})

export const {signInStart,signInSuccess,signInFailure,passcodeFailure, clearError} = userSlice.actions;
export default userSlice.reducer